import type { CarbonEmission, CarbonEmissionIntensities, EnhancedSpace } from "@/Api/generated";

import { memo, useState } from "react";

import ButtonGroup from "@/components/Button/ButtonGroup";
import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import BarChart from "@/components/Charts/BarChart";

import { ChartColors } from "@/js/constants/cambio";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getSelectedYear, useDashboardContext, useDashboardResources } from "../utils";

// Used to download only carbon emissions data, used in the benchmarks drawer
export const co2eEmissionsDownloadAction = (
  carbonEmissionsData: any,
  property: EnhancedSpace,
  organizationName: any,
) => {
  convertToCsvAndDownload(
    [
      { title: "End Date", key: "date" },
      {
        title: "Carbon Emissions",
        key: "carbon",
        processValue: (value) => {
          return parseFloat(value).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          });
        },
      },
      { title: "Carbon Emissions Unit of Measurement", value: "metric tons" },
    ],
    carbonEmissionsData,
    (property ? property.name : organizationName) + " Carbon Emissions",
  );
};

// Used to download only carbon emissions intensity data, used in the benchmarks drawer
export const co2eEmissionsIntensityDownloadAction = (
  carbonEmissionIntensityData: any,
  property: EnhancedSpace,
  organizationName: any,
) => {
  convertToCsvAndDownload(
    [
      { title: "End Date", key: "date" },
      {
        title: "Carbon Emissions Intensity",
        key: "carbon_emission_intensity.value",
        processValue: (value) => {
          return parseFloat(value).toLocaleString("en-US", {
            maximumFractionDigits: 0,
          });
        },
      },
      { title: "Carbon Emissions Intensity Unit of Measurement", value: "kg CO2e psf" },
    ],
    carbonEmissionIntensityData,
    (property ? property.name : organizationName) + " Carbon Emissions Intensity",
  );
};

// Used to download both carbon emissions and  carbon emissions intensity data, used in this card
export const co2eEmissionsAndIntensityDownloadAction = (
  carbonEmissionsData: CarbonEmission[],
  carbonEmissionIntensityData: CarbonEmissionIntensities[],
  property: EnhancedSpace,
  organizationName: any,
) => {
  convertToCsvAndDownload(
    [
      { title: "End Date", key: "date" },
      {
        title: "Carbon Emissions",
        key: "carbon",
        processValue: (value) => {
          return parseFloat(value).toLocaleString("en-US", {
            maximumFractionDigits: 1,
          });
        },
      },
      { title: "Carbon Emissions Unit of Measurement", value: "metric tons" },
      {
        title: "Carbon Emissions Intensity",
        key: "carbon_emission_intensity",
        processValue: (value) => {
          return parseFloat(value.value).toLocaleString("en-US", {
            maximumFractionDigits: 1,
          });
        },
      },
      { title: "Carbon Emissions Intensity Unit of Measurement", value: "kg CO2e psf" },
    ],
    carbonEmissionsData.map((item, index) => {
      return {
        date: item.date,
        carbon: item.carbon,
        carbon_emission_intensity: carbonEmissionIntensityData[index].carbon_emission_intensity,
      };
    }),
    (property ? property.name : organizationName) + " Carbon Emissions and Intensity",
  );
};

enum States {
  TOTAL,
  INTENSITY,
}

export default memo(function CO2eEmissionsCard() {
  const [view, setView] = useState<States>(States.TOTAL);
  const { organizationName, featureConfigurations } = useAppContext();
  const { property, dateRange } = useDashboardContext();
  const { energySourceModel, isLoading, hasInitiallyLoaded, hasErrored } = useDashboardResources(
    ["property", "energySource"],
    { useAnnualizedData: !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED },
  );
  const carbonEmissionsData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energySourceModel.get("annual_carbon_emission") || []
    : energySourceModel.get("monthly_carbon_emission");
  const carbonEmissionIntensityData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energySourceModel.get("annual_carbon_emission_intensity") || []
    : energySourceModel.get("monthly_carbon_emission_intensity");

  return (
    <CambioCard
      title="Carbon Emissions"
      label={view === States.TOTAL ? "metric tons CO2e" : "kg CO2e psf"}
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <>
          <ButtonGroup
            items={[
              { key: States.TOTAL, text: "Total" },
              { key: States.INTENSITY, text: "Intensity" },
            ]}
            selected={view}
            onClick={setView}
          />

          <CardDownloadButton
            name="Carbon Emissions and Intensity"
            onClick={() => {
              co2eEmissionsAndIntensityDownloadAction(
                carbonEmissionsData,
                carbonEmissionIntensityData,
                property,
                organizationName,
              );
            }}
          />
        </>
      }
    >
      {hasInitiallyLoaded ?
        <BarChart
          data={view === States.TOTAL ? carbonEmissionsData : carbonEmissionIntensityData}
          xAxisKey="date"
          hasLegend={false}
          yAxisChartDataFields={[
            {
              key: view === States.TOTAL ? "carbon" : "carbon_emission_intensity.value",
              name: view === States.TOTAL ? "CO2e" : "CO2e intensity",
              color: ChartColors.TEAL_CAMBIO,
              style: (entry) =>
                (
                  featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED &&
                  entry.date !== getSelectedYear(dateRange)
                ) ?
                  { opacity: 0.3 }
                : {},
            },
          ]}
          tooltipValueFormatter={(value) =>
            value.toLocaleString("en-US", { maximumFractionDigits: 1 })
          }
        />
      : null}
    </CambioCard>
  );
});
