import React from "react";
import {
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart as RechartsRadarChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import SvgIcon, { IconsType } from "@/components/SvgIcon";

import { ChartColors } from "@/js/constants/cambio";

import ChartKpiTitle from "../ChartKpiTitle";
import ChartLegend from "../ChartLegend";
import ChartTooltip from "../ChartTooltip";

interface RadarChartProps {
  /**
   * An array of objects which define the fields (dimensions) of the radar chart
   *
   */
  dataFields: Array<{
    icon: IconsType; // The icon to display next to the data field label
    key: string; // A unique key for the data field
    name: string; // The display name for the data field
  }>;
  /**
   * An array of objects containing the data for each radar chart series. Each object should contain keys for each data field defined in the dataFields prop
   */
  data: Array<Record<string, any>>;
  /**
   * An optional array of colors to use for the radar chart series
   */
  colors?: { fill: string; stroke: string }[];
  /**
   * An optional string specifying the key in the data objects to use as the series name
   */
  nameKey?: string;
  /**
   * An optional function to format the tooltip label
   */
  tooltipLabelFormatter?: (label: string) => string;
  /**
   * An optional function to format the tooltip value
   */
  tooltipValueFormatter?: (value: number) => string;
}

const DEFAULT_COLORS = [
  { fill: ChartColors.PINK, stroke: "#AF2C83" },
  { fill: ChartColors.BLUE_SKY, stroke: "#16698E" },
];

const RadarChart: React.FC<RadarChartProps> = ({
  dataFields,
  data,
  colors = DEFAULT_COLORS,
  nameKey = "name",
  tooltipLabelFormatter = (label) => label,
  tooltipValueFormatter = (value) => String(value),
}) => {
  const radius = 150;
  const objectHeight = 60;

  // This component is used for rendering custom labels on the radar chart
  // Note: currently there is only an example in our designs with 5 data fields. This component has been tested for 3-8 labels but may need label positioning adjustments for other numbers of labels other than 5 pending future use cases
  const RadarChartLabel = (props: any) => {
    const { payload, x, y, cx, cy } = props;
    const angle = Math.atan2(y - cy, x - cx);

    let quadrant = "";

    // Determine the quadrant based on the angular position of the label

    // Handle  cases for angles around 0, 90, 180, and 270 degrees with a buffer
    if (angle > -Math.PI / 8 && angle <= Math.PI / 8) {
      quadrant = "x-axis-right";
    } else if (angle > Math.PI / 2 - Math.PI / 8 && angle <= Math.PI / 2 + Math.PI / 8) {
      quadrant = "y-axis";
    } else if (angle > Math.PI - Math.PI / 8 || angle <= -Math.PI + Math.PI / 8) {
      quadrant = "x-axis-left";
    } else if (angle > -Math.PI / 2 - Math.PI / 8 && angle <= -Math.PI / 2 + Math.PI / 8) {
      quadrant = "y-axis";
    }
    // bottom right quadrant
    else if (angle > Math.PI / 8 && angle < Math.PI / 2 - Math.PI / 8) {
      quadrant = "bottom-right";
    }
    // bottom left quadrant
    else if (angle > Math.PI / 2 + Math.PI / 8 && angle < Math.PI - Math.PI / 8) {
      quadrant = "bottom-left";
    }
    // top left quadrant
    else if (angle > -Math.PI + Math.PI / 8 && angle < -Math.PI / 2 - Math.PI / 8) {
      quadrant = "top-left";
    }
    // top right quadrant
    else {
      quadrant = "top-right";
    }

    let labelX, labelY;

    // Calculate the label position based on the angle,
    // made to be compatible with the case where the bottom labels are placed level with the chart vertices not below them as projected out from the axis line

    // bottom right quadrant
    if (angle > Math.PI / 8 && angle < Math.PI / 2 - Math.PI / 8) {
      labelX = cx + radius - 12;
      labelY = y - objectHeight;
    }
    // bottom left quadrant
    else if (angle > Math.PI / 2 + Math.PI / 8 && angle < Math.PI - Math.PI / 8) {
      labelX = cx - radius * 2 + 12;
      labelY = y - objectHeight;
    }
    // top left quadrant
    else if (angle > -Math.PI + Math.PI / 8 && angle < -Math.PI / 2 - Math.PI / 8) {
      labelX = x - radius;
      labelY = y - objectHeight;
    }
    // top right quadrant
    else {
      labelX = x;
      labelY = y - objectHeight;
    }

    if (angle > Math.PI / 2 - Math.PI / 8 && angle <= Math.PI / 2 + Math.PI / 8) {
      labelX = x - radius / 2;
      labelY = y;
    } else if (angle > -Math.PI / 2 - Math.PI / 8 && angle <= -Math.PI / 2 + Math.PI / 8) {
      labelX = x - radius / 2;
      labelY = y - objectHeight;
    } else if (angle > -Math.PI / 8 && angle <= Math.PI / 8) {
      labelX = x + 20;
      labelY = y - objectHeight / 2;
    } else if (angle > Math.PI - Math.PI / 8 || angle <= -Math.PI + Math.PI / 8) {
      labelX = x - radius - 20;
      labelY = y - objectHeight / 2;
    }

    // Find the corresponding dataField object based on the payload value
    const dataField = dataFields.find((field) => field.key === payload.value);
    const iconPath = dataField?.icon;
    const name = dataField?.name;

    // Render the label as a foreignObject with the icon and text
    return (
      <g>
        <foreignObject x={labelX} y={labelY} width={radius} height={objectHeight}>
          <div className={`label-container ${quadrant}`}>
            {iconPath && <SvgIcon name={dataField?.icon} />}
            <ChartKpiTitle>{name}</ChartKpiTitle>
          </div>
        </foreignObject>
      </g>
    );
  };

  // transformedData is a transformed version of the data suitable for rendering with Recharts
  // It creates an object for each data field, where the keys are the data field keys,
  // and the values are the corresponding data points from the data array
  const transformedData = dataFields.map((field) => {
    const dataObject: Record<string, any> = { subject: field.key };

    data.forEach((entry, index) => {
      dataObject[`k${index}`] = entry[field.key];
    });

    return dataObject;
  });

  return (
    <div className="Chart RadarChart">
      <ResponsiveContainer
        width="100%"
        //When the number of data fields is even,there is a label at the bottom center of the chart; if this label is not present there is a gap at the bottom of the chart between it and the legend. This conditional statement adjusts the height of the chart to account for this gap when the bottom chart label isn't present.
        height={dataFields.length % 2 === 0 ? 450 : 400}
      >
        <RechartsRadarChart
          data={transformedData}
          cx="50%"
          cy={dataFields.length % 2 === 0 ? 225 : 250}
          outerRadius={radius}
        >
          <PolarGrid />
          <PolarAngleAxis dataKey="subject" tick={(props) => <RadarChartLabel {...props} />} />

          {data.map((entry, index) => (
            <Radar
              key={`radar-${index}`}
              name={entry[nameKey]}
              dataKey={`k${index}`}
              stroke={colors[index % colors.length].stroke}
              fill={colors[index % colors.length].fill}
              fillOpacity={0.3}
            />
          ))}

          <Tooltip
            cursor={{
              fill: getComputedStyle(document.documentElement).getPropertyValue("--gray-2"),
            }}
            isAnimationActive={false}
            content={({ payload }) => {
              const datapoint = dataFields.find(
                (item) => item["key"] === payload[0]?.payload.subject,
              );

              return (
                <ChartTooltip
                  data={{ ...payload[0]?.payload, [nameKey]: datapoint?.name }}
                  chartDataFields={data.map((entry, index) => ({
                    key: `k${index}`,
                    color: colors[index % colors.length].fill,
                    name: entry[nameKey],
                  }))}
                  labelKey={nameKey}
                  labelFormatter={tooltipLabelFormatter}
                  valueFormatter={tooltipValueFormatter}
                />
              ) as any;
            }}
          />
        </RechartsRadarChart>
      </ResponsiveContainer>
      <ChartLegend
        chartLegendItems={data.map((entry, index) => ({
          name: entry[nameKey],
          color: colors[index % colors.length].fill,
        }))}
      />
    </div>
  );
};

export default RadarChart;
