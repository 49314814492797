import { ReactNode, useCallback, useEffect, useRef } from "react";

import useOverflow from "@/js/hooks/useOverflow";
import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { classnames } from "@/js/utils/cambio";

import IconButton from "../Button/IconButton";
import ErrorBoundary from "../ErrorBoundary";
import { PortalContext } from "../Portal";
import { type SidePanelContext } from "./SidePanelContext";

interface SidePanelProps {
  className?: string;
  size?: "small" | "medium";
  children: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
  title?: string;
  /** This allows us to prevent closing the drawer when we want an unsaved changes modal */
  onBeforeClose?: (closePanel: SidePanelContext["closePanel"]) => void;
}

/**
 * Main side panel component that all other side panel wrappers should use, just like the <Modal>
 * component.
 */
export default function SidePanel({
  className,
  children,
  header,
  footer,
  title,
  onBeforeClose,
  size = "small",
}: SidePanelProps) {
  const { containerRef, closePanel } = useSidePanelContext();
  const onClose = useCallback(
    () => (onBeforeClose ? onBeforeClose(closePanel) : closePanel()),
    [onBeforeClose],
  );

  const panelRef = useRef<HTMLElement>();
  const { overflowClassNames, onScroll } = useOverflow(panelRef);

  /**
   * Handlers for closing the panel when the user clicks outside the panel or hits the escape key.
   */
  useEffect(() => {
    const onClickOutside = (evt: MouseEvent) => {
      if (evt.target === containerRef.current) {
        onClose();
      }
    };

    const onKeyDown = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("click", onClickOutside);

    return () => {
      document.removeEventListener("click", onClickOutside);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onBeforeClose]);

  return (
    <PortalContext.Provider value={{ parent: containerRef }}>
      <aside
        ref={panelRef}
        className={classnames(
          "SidePanel",
          className,
          { [size]: size !== "small" },
          overflowClassNames,
        )}
        onScroll={onScroll}
      >
        <ErrorBoundary>
          <header>
            {title ?
              <h2>{title}</h2>
            : null}
            {header}
            <IconButton icon="x" onClick={onClose} />
          </header>
          <section>{children}</section>
          {footer ?
            <footer>{footer}</footer>
          : null}
        </ErrorBoundary>
      </aside>
    </PortalContext.Provider>
  );
}
