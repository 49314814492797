import React, { useMemo } from "react";
import {
  Area,
  CartesianGrid,
  AreaChart as RechartsAreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartDataField } from "@/js/types/charts";
import { getNestedValue } from "@/js/utils/cambio";
import {
  getCartesianGridProps,
  getRechartsTooltipProps,
  getRechartsXAxisStyleProps,
  getRechartsYAxisStyleProps,
  getReferenceLineConfig,
} from "@/js/utils/charts";

import ChartLegend from "../ChartLegend";

/**
 * Represents an area chart component that can display stacked area data and optionally a reference line.
 * Tooltip and legend functionality is also integrated, with options to customize tooltip content and format, as well as showing legends.
 */

function AreaChart({
  data,
  xAxisKey,
  yAxisChartDataFields,
  height = "100%",
  width = "100%",
  yAxisTickFormatter = (tick) => tick.toLocaleString("en-US"),
  transformValue = (value) => value,
  tooltipValueFormatter = (value = 0) => value.toLocaleString("en-US"),
  tooltipLabelKey = "date",
  tooltipLabelFormatter = (label) => String(label).replace("-", " '"),
  hasLegend = true,
  hasReferenceLine = true,
}: {
  /** The dataset for the chart, where each element is an object representing a single data point. */
  data: Record<string, any>[];
  /** The key from the data objects used for x-axis values. */
  xAxisKey: string;
  /** An array of configurations defining each area series to be displayed on the y-axis. */
  yAxisChartDataFields: ChartDataField[];
  /** The height of the chart container, can be a number (for pixels) or a string (like '100%'). Defaults to "100%". */
  height?: string | number;
  /** The width of the chart container, can be a specific number (pixels) or a percentage string. Defaults to "100%". */
  width?: string | number;
  /** A function to format the Y-axis tick labels. Defaults to formatting numbers as strings in the "en-US" locale. */
  yAxisTickFormatter?: (tick: number) => string;
  /** A function to transform raw data values into a format suitable for display or calculation. */
  transformValue?: (value: any) => any;
  /** The key from the data items used to derive labels for tooltips. Defaults to 'date'. */
  tooltipLabelKey?: string;
  /** A function to format the numeric values displayed in tooltips. Defaults to locale string formatting in "en-US". */
  tooltipValueFormatter?: (value: number) => string;
  /** A function to format the labels displayed in tooltips. Defaults to replacing "-" with a stylized apostrophe. */
  tooltipLabelFormatter?: (label: string | number) => string;
  /** Flag indicating whether a legend should be displayed. Defaults to true. */
  hasLegend?: boolean;
  /** Flag indicating whether a reference line should be displayed. Defaults to true. */
  hasReferenceLine?: boolean;
}) {
  // This function calculates the combined value of data at each x-axis point and is used for calculating the reference line segment.
  function calculateTotalValues(data: any[], yAxisChartDataFields: ChartDataField[]) {
    const returnMap = data.map((dataItem) => {
      const totalValue = yAxisChartDataFields.reduce((sum, field) => {
        const value = transformValue(getNestedValue(dataItem, field.key));

        return sum + (value || 0);
      }, 0);

      return {
        ...dataItem,
        calculated_total_value: totalValue,
      };
    });

    return returnMap;
  }

  const yAxisConfig = useMemo(
    () =>
      getRechartsYAxisStyleProps(data, transformValue, yAxisChartDataFields, yAxisTickFormatter, [
        yAxisChartDataFields?.map((field) => field.key),
      ]),
    [JSON.stringify(data), yAxisChartDataFields, tooltipValueFormatter],
  );

  const totalValuesData = calculateTotalValues(data, yAxisChartDataFields);

  const xAxisConfig = getRechartsXAxisStyleProps(xAxisKey, tooltipLabelFormatter);

  return (
    <div className="Chart AreaChart">
      <ResponsiveContainer width={width} height={height}>
        <RechartsAreaChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <XAxis {...xAxisConfig} />
          <YAxis {...yAxisConfig} />
          <Tooltip
            {...getRechartsTooltipProps(
              totalValuesData.map((entry) => ({
                ...entry,
                total: entry.calculated_total_value,
                breakdown: yAxisChartDataFields.map(({ key, color, name }) => ({
                  color,
                  name,
                  value: entry[key],
                })),
              })),
              xAxisKey,
              [{ key: "total", color: "", name: "Total", iconShape: null }],
              tooltipLabelKey,
              tooltipLabelFormatter,
              tooltipValueFormatter,
            )}
          />
          <CartesianGrid {...getCartesianGridProps()} />
          {yAxisChartDataFields.map((yKey) => (
            <Area
              type="monotone"
              dataKey={(dataObject) => {
                return transformValue(getNestedValue(dataObject, yKey.key)) || 0;
              }}
              fill={yKey.color}
              stroke={yKey.color}
              fillOpacity={0.3}
              stackId="1"
              name={yKey.name}
            />
          ))}
          {hasReferenceLine ?
            <ReferenceLine
              {...getReferenceLineConfig(totalValuesData, xAxisKey, "calculated_total_value")}
            />
          : null}
        </RechartsAreaChart>
      </ResponsiveContainer>

      {hasLegend ?
        <ChartLegend chartLegendItems={yAxisChartDataFields} />
      : null}
    </div>
  );
}

export default AreaChart;
