import type { ExecutorFunction } from "resourcerer";

import { useRouter } from "next/router";
import { useResources } from "resourcerer";

import CambioCard from "@/components/CambioCard";
import Table from "@/components/Table";

import { getLoadingCell } from "@/js/utils/partials";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

type CertificationTypes = "EnergyStar" | "LEED";

const certificationImages: Record<CertificationTypes, string> = {
  EnergyStar: "/images/certificationIcon/energyStarIcon.svg",
  LEED: "/images/certificationIcon/leedIcon.svg",
};

const getResources: ExecutorFunction<
  "certifications",
  { subPortfolioToken: string; propertyToken: string }
> = ({ subPortfolioToken, propertyToken }) => ({
  certifications: {
    path: {
      subPortfolioToken,
      propertyToken,
    },
  },
});

export default function CertificationsCard() {
  const { organizationToken } = useAppContext();
  const { space_token } = useRouter().query;
  const { certificationsCollection, isLoading, hasErrored } = useResources(getResources, {
    subPortfolioToken: organizationToken,
    propertyToken: space_token as string,
  });

  return (
    <CambioCard className="CertificationsCard" title="Certifications" hasErrored={hasErrored}>
      <Table
        copyable={false}
        head={["Certification", "Status", "Last Certification Date"]}
        body={(["LEED", "EnergyStar"] as CertificationTypes[])
          .map((certificationType) => ({
            certificationType,
            ...certificationsCollection
              .toJSON()
              .find((cert) => cert.certification_type === certificationType),
          }))
          .map(({ certificationType, status, last_certification_date }) => [
            <span className="identifier">
              <img src={certificationImages[certificationType]} />
              {certificationType}
            </span>,
            isLoading ? getLoadingCell() : status || "--",
            isLoading ? getLoadingCell() : last_certification_date || "--",
          ])}
      />
    </CambioCard>
  );
}
