import dayjs from "dayjs";
import { useRouter } from "next/router";
import { ExecutorFunction, useResources } from "resourcerer";

import CambioCard from "@/components/CambioCard";
import { PieChart } from "@/components/Charts/PieChart";
import { FilterTypes } from "@/components/Filters";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getFiltersQueryParams, isCampus, useDashboardContext } from "../utils";

export const getResources: ExecutorFunction<
  "scopeBreakdown",
  {
    space_token: string;
    organization_token: string;
    enabled: boolean;
    start_date?: string;
    end_date?: string;
    filters?: Record<FilterTypes, string>;
  }
> = ({ space_token, organization_token, enabled, start_date, end_date, filters }) =>
  enabled ?
    {
      scopeBreakdown: {
        params: {
          start_date,
          end_date,
          ...filters,
          ...(space_token ?
            { [isCampus(space_token) ? "campus_token" : "space_token"]: space_token }
          : {}),
        },
        path: { organization_token },
      },
    }
  : {};

export default function ScopeBreakdownCard() {
  const { featureConfigurations, organizationToken } = useAppContext();
  const { propertyLoadingStates, dateRange, filters } = useDashboardContext();
  const { isLoading, hasInitiallyLoaded, hasErrored, scopeBreakdownCollection } = useResources(
    getResources,
    {
      ...(useRouter().query as { space_token: string }),
      organization_token: organizationToken,
      enabled: featureConfigurations.ORG_LEVEL_SCOPE_BREAKDOWN_CHART_ENABLED,
      start_date: dayjs(dateRange[0]).format("YYYY-MM-DD"),
      end_date: dayjs(dateRange[1]).format("YYYY-MM-DD"),
      filters: getFiltersQueryParams(filters),
    },
  );

  const data = scopeBreakdownCollection?.toJSON().filter((datum) => {
    return datum.scope !== "1-2";
  });

  return (
    <CambioCard
      title="Scope Breakdown"
      label="metric tons CO2e"
      disabled={!featureConfigurations.ORG_LEVEL_SCOPE_BREAKDOWN_CHART_ENABLED}
      hasErrored={hasErrored || propertyLoadingStates.hasErrored}
      isLoading={isLoading || propertyLoadingStates.isLoading}
      className="ScopeBreakdownCard"
    >
      {hasInitiallyLoaded ?
        featureConfigurations.ORG_LEVEL_SCOPE_BREAKDOWN_CHART_ENABLED ?
          <PieChart
            data={data}
            dataKey="percent"
            nameKey="scope"
            nameFormatter={(name) => `Scope ${name}`}
            flavor="skinny-no-center-content"
            tooltipLabelKey="scope"
            tooltipLabelFormatter={(label) => `Scope ${label}`}
            tooltipValueFormatter={(value, scopeBreakdown) =>
              value?.toLocaleString("en-US", { maximumFractionDigits: 1 }) + "%"
            }
            legendPosition="right"
            showLegendValue
            legendValueKey="carbon_emissions"
            legendValueFormatter={(value) =>
              value?.toLocaleString("en-US", { maximumFractionDigits: 1 }) + ""
            }
            sorter={(a, b) => {
              return a.scope.localeCompare(b.scope);
            }}
          />
        : <img
            src="https://utility-statements-test.s3.us-west-2.amazonaws.com/scope_breakdown.png"
            width="100%"
            height="30%"
            alt="property image"
          />

      : null}
    </CambioCard>
  );
}
