import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import CambioCard from "@/components/CambioCard";
import ChartLabel from "@/components/Charts/ChartLabel";
import { getPresetFromDateRange } from "@/components/DateRangePicker";
import Delta from "@/components/Delta";
import MetricValue from "@/components/MetricValue";
import ProgressBar from "@/components/ProgressBar";

import { ChartColors } from "@/js/constants/cambio";
import { classnames } from "@/js/utils/cambio";

import { presetToLabel } from "./util";

dayjs.extend(duration);
type DateRange = [string, string];

const SpotTrendCard = ({
  currentValue,
  priorValue,
  unitLabel,
  descriptionName = "",
  changePercentage,
  isAggregate = false,
  dateRange,
  isYearOverYear,
  isLoading,
  maximumFractionDigits,
}: {
  currentValue: number;
  priorValue: number | null;
  unitLabel: string;
  descriptionName?: string;
  changePercentage: number;
  isAggregate: boolean;
  dateRange: DateRange;
  isYearOverYear: boolean;
  isLoading: boolean;
  maximumFractionDigits: number;
}) => {
  const maxValue = Math.max(currentValue, priorValue);

  const { currentLabel, previousLabel, currentPeriod, previousPeriod } = presetToLabel(
    getPresetFromDateRange(dateRange),
    dateRange,
    isYearOverYear,
  );

  return (
    <CambioCard
      title={isYearOverYear ? "Year over Year Trend" : "Period over Period Trend"}
      className="SpotTrendCard"
      icon="trend-up"
      isLoading={isLoading}
      subtitle={
        priorValue ?
          <>
            Your {isAggregate ? "portfolio's" : "property's"} {descriptionName} is{" "}
            <span
              className={classnames("change-percentage-text", {
                green: changePercentage < 0,
                red: changePercentage > 0,
              })}
            >
              {changePercentage ? Math.abs(changePercentage).toFixed(0) + "%" : ""}{" "}
              {changePercentage < 0 ?
                "lower"
              : changePercentage > 0 ?
                "higher"
              : "no lower or higher"}
            </span>
            {isYearOverYear ?
              "in this time frame for the selected year than previous year."
            : "for the selected period than the previous period."}
          </>
        : "You do not have any data in the prior period. Stick with Cambio and we'll soon get you that trend analysis!"
      }
    >
      <section>
        <header>
          <h5>{isYearOverYear ? "This Year" : `Selected ${currentLabel}`}:</h5>
          <span>{currentPeriod}</span>
        </header>
        <div className="value-content">
          <MetricValue size="large">
            {currentValue?.toLocaleString("en-US", {
              maximumFractionDigits: maximumFractionDigits,
            })}
          </MetricValue>
          <ChartLabel>{unitLabel}</ChartLabel>
          {changePercentage ?
            <Delta value={changePercentage} inverted />
          : <span>--</span>}
        </div>

        <ProgressBar
          progress={priorValue ? currentValue / maxValue : 1}
          color={ChartColors.TEAL_CAMBIO}
          size="large"
        />
      </section>
      <section>
        <header>
          <h5>{isYearOverYear ? "Last Year" : `Previous ${previousLabel}`}:</h5>
          <span>{previousPeriod}</span>
        </header>
        <div className="value-content">
          <MetricValue size="large">
            {priorValue ?
              priorValue?.toLocaleString("en-US", {
                maximumFractionDigits: maximumFractionDigits,
              })
            : "--"}
          </MetricValue>
          <ChartLabel>{unitLabel}</ChartLabel>
        </div>

        <ProgressBar
          progress={priorValue ? priorValue / maxValue : 0}
          color="#BFC5CF"
          size="large"
        />
      </section>
    </CambioCard>
  );
};

export default SpotTrendCard;
