import React, { useMemo } from "react";
import {
  Area,
  CartesianGrid,
  Line,
  ComposedChart as RechartsComposedChart,
  ReferenceArea,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { ChartDataField } from "@/js/types/charts";
import {
  getCartesianGridProps,
  getRechartsTooltipProps,
  getRechartsXAxisStyleProps,
  getRechartsYAxisStyleProps,
  getReferenceLineConfig,
} from "@/js/utils/charts";

import ChartLegend, { ChartLegendItem } from "../ChartLegend";

export type ReferenceDotShape = "dot" | "circle" | (() => JSX.Element);

export type ReferenceDotConfig = {
  x: number | string;
  y: number;
  shape_cx?: number;
  shape_cy?: number;
  name: string;
  color: string;
  shape: ReferenceDotShape;
};

type LineChartDataField = {
  lineType?: "straight" | "dashed";
  lineDot?: { fill: string; r: number };
  ref?: any;
  onAnimationEnd?: () => void;
  strokeColor?: string;
  isAnimated?: boolean;
} & ChartDataField;

type AreaConfig = {
  dataKey: string;
  fill: string;
  stroke?: string;
  fillOpacity?: number;
  strokeOpacity?: number;
};

export interface LineChartProps {
  /** The data to be displayed by the chart. */
  data: Record<string, any>[];
  /** The key from the data objects used for x-axis values. Defaults to 'date'. */
  xAxisKey?: string;
  /** Array of configurations for each line chart's y-axis. */
  yAxisChartDataFields: LineChartDataField[];
  /** Key used to fetch the tooltip label from the data. Defaults to 'date'. */
  tooltipLabelKey?: string;
  /** Function to format the tooltip label. */
  tooltipLabelFormatter?: (label: string | number) => string;
  /** Function to format the tooltip value. */
  tooltipValueFormatter?: (value: number) => string;
  /** We will use the tooltip formatter by default, but use this if tooltip and tick formatting differ */
  tickFormatter?: (label: string | number) => string;
  /** Flag to indicate if a chart legend should be displayed. Defaults to false. */
  hasLegend?: boolean;
  /** Flag to indicate if a reference line should be displayed. Defaults to false. */
  hasReferenceLine?: boolean;
  /** Key used to determine where the reference line should be drawn. */
  referenceLineKey?: string;
  /** Width of the lines in the chart; can be 'small' or 'medium'. */
  lineWidth?: "small" | "medium";
  /** Array of configurations for each reference dot to be displayed on the chart. Defaults to an empty array. */
  referenceDots?: ReferenceDotConfig[];
  referenceAreas?: any[];
  /** Array of configurations for each area to be displayed on the chart. Defaults to an empty array. */
  areaConfigs?: AreaConfig[];
  /** Items to display in the chart legend. */
  legendItems?: ChartLegendItem[];
  /** Width of the chart container. Defaults to '100%'. */
  width?: string | number;
  /** Height of the chart container. Defaults to '100%'. */
  height?: string | number;
  /** Flag to indicate if the dot on the active (hovered) chart line should be visible. Defaults to true. */
  activeDot?: boolean;
  ticks?: string[] | number[];
}

const LineChart = ({
  data,
  xAxisKey = "date",
  yAxisChartDataFields,
  tooltipLabelKey = "date",
  tooltipLabelFormatter = (label) => String(label)?.replace("-", " ‘"),
  tickFormatter,
  tooltipValueFormatter = (value) =>
    (value ?? 0).toLocaleString("en-US", { maximumFractionDigits: 1 }),
  hasLegend = false,
  hasReferenceLine = false,
  referenceLineKey = "value",
  lineWidth = "medium",
  referenceDots = [],
  referenceAreas = [],
  areaConfigs = [],
  legendItems,
  width = "100%",
  height = "100%",
  activeDot = true,
  ticks,
}: LineChartProps) => {
  const yAxisConfig = useMemo(
    () =>
      getRechartsYAxisStyleProps(
        data,
        (value: number) => value,
        yAxisChartDataFields,
        tooltipValueFormatter,
      ),
    [JSON.stringify(data), yAxisChartDataFields, tooltipValueFormatter],
  );

  const xAxisConfig = getRechartsXAxisStyleProps(xAxisKey, tickFormatter || tooltipLabelFormatter);

  const renderReferenceDotShape = (referenceDotConfig: ReferenceDotConfig) => {
    switch (referenceDotConfig.shape) {
      case "dot":
        return (
          <circle
            cx={referenceDotConfig.shape_cx}
            cy={referenceDotConfig.shape_cy}
            r="5"
            fill={referenceDotConfig.color}
          />
        );
      case "circle":
        return (
          <circle
            cx={referenceDotConfig.shape_cx}
            cy={referenceDotConfig.shape_cy}
            r="10"
            strokeWidth={3}
            stroke={referenceDotConfig.color}
            fill="transparent"
            textAnchor="middle"
            dominantBaseline="middle"
          />
        );
    }
  };

  return (
    <div className="Chart LineChart">
      <ResponsiveContainer width={width} height={height}>
        <RechartsComposedChart data={data} margin={{ top: 16, bottom: 0 }}>
          <XAxis {...xAxisConfig} ticks={ticks} />
          <YAxis {...yAxisConfig} />

          {areaConfigs.map(({ dataKey, fill, stroke = "#00000000", fillOpacity = 1 }) => (
            <Area
              type="monotone"
              dataKey={dataKey}
              stroke={stroke}
              fill={fill}
              fillOpacity={fillOpacity}
              fillRule="nonzero"
            />
          ))}

          <Tooltip
            {...getRechartsTooltipProps(
              data,
              xAxisKey,
              yAxisChartDataFields,
              tooltipLabelKey,
              tooltipLabelFormatter,
              tooltipValueFormatter,
            )}
          />

          <CartesianGrid {...getCartesianGridProps()} />
          {referenceAreas.map(({ x1, x2, y1, y2, fill }) => (
            <ReferenceArea x1={x1} x2={x2} y1={y1} y2={y2} fill={fill} />
          ))}

          {yAxisChartDataFields.map(
            ({
              key,
              color,
              name,
              lineType = "straight",
              ref,
              lineDot,
              isAnimated,
              strokeColor,
              onAnimationEnd,
            }) => (
              <Line
                type="monotone"
                dataKey={key}
                stroke={strokeColor ? strokeColor : color}
                strokeWidth={lineWidth === "medium" ? 3 : 2}
                dot={lineDot ? lineDot : false}
                name={name}
                strokeDasharray={lineType === "dashed" ? "5 5" : undefined}
                ref={ref}
                activeDot={activeDot}
                isAnimationActive={isAnimated}
                onAnimationEnd={onAnimationEnd}
              />
            ),
          )}

          {hasReferenceLine && (
            <ReferenceLine {...getReferenceLineConfig(data, xAxisKey, referenceLineKey)} />
          )}
          {referenceDots.map((dot) => (
            <ReferenceDot
              x={dot.x}
              y={dot.y}
              shape={(props) =>
                renderReferenceDotShape({
                  shape_cx: props.cx,
                  shape_cy: props.cy,
                  ...dot,
                })
              }
            />
          ))}
        </RechartsComposedChart>
      </ResponsiveContainer>
      {hasLegend && (
        <ChartLegend chartLegendItems={legendItems ? legendItems : yAxisChartDataFields} />
      )}
    </div>
  );
};

export default LineChart;
