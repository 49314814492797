import type { ExecutorFunction } from "resourcerer";

import { memo, MutableRefObject, useEffect, useState } from "react";
import { useResources } from "resourcerer";

import CambioCard from "@/components/CambioCard";
import ComposedChart from "@/components/Charts/ComposedChart";
import LineChart from "@/components/Charts/LineChart";
import { Filter, FilterTypes } from "@/components/Filters";
import Select from "@/components/Select";

import { ChartColors } from "@/js/constants/cambio";
import { classnames } from "@/js/utils/cambio";

import { useAppContext } from "@/layouts/AppLayout/AppContext";
import { getFiltersQueryParams } from "@/pages/DashboardPage/DashboardContent/utils";
import { PathToNetZeroTimeframe } from "@/pages/PathToNetZeroPage/constants";

import { transformData } from "./util";

const Timeframes = [
  { display: "Total", value: PathToNetZeroTimeframe.TOTAL },
  { display: "1-Year", value: PathToNetZeroTimeframe.ONE_YEAR },
];

const getResources: ExecutorFunction<
  "emissionReduction" | "emissionReductionOld",
  {
    orgToken: string;
    organization_id: string;
    hasIntelligentPath: boolean;
    filters: Record<FilterTypes, string>;
  }
> = ({ orgToken, organization_id, ...props }) =>
  props.hasIntelligentPath ?
    { emissionReduction: { params: props.filters, path: { orgToken } } }
  : { emissionReductionOld: { params: { organization_id, ...props.filters } } };

export default memo(function PathToNetZeroCard({
  canChangeTimeFrame = true,
  onPledgeUpdateRef,
  isAggregate = false,
  filters,
}: {
  canChangeTimeFrame?: boolean;
  /**
   * Ref passed down to attach the refetch function to so that the TargetSettingsCard can call
   * it after a pledge is updated. This will only exist on the PTNZ page.
   */
  onPledgeUpdateRef?: MutableRefObject<() => void>;
  isAggregate?: boolean;
  filters?: Filter[];
}) {
  const [timeframe, setTimeframe] = useState<PathToNetZeroTimeframe>(PathToNetZeroTimeframe.TOTAL);

  const {
    featureConfigurations,
    organizationToken,
    isInPortfolioContext,
    organizationId,
    organizationName,
  } = useAppContext();
  // NOTE: total portfolio view does not have intelligent PTNZ for now, even if subportfolio does
  const hasIntelligentPath =
    !isInPortfolioContext && !!featureConfigurations.ORG_LEVEL_INTELLIGENT_PATH_TO_NET_ZERO_ENABLED;

  const {
    hasInitiallyLoaded,
    isLoading,
    hasErrored,
    refetch,
    emissionReductionModel,
    emissionReductionOldModel,
  } = useResources(getResources, {
    hasIntelligentPath,
    organization_id: organizationId,
    orgToken: organizationToken,
    filters: getFiltersQueryParams(filters),
  });

  /**
   * This refetch attached to a ref is a way to keep the refetch and data loading in this component
   * instead of needing to lift it up so that the TargetSettingsCard can access it.
   */
  useEffect(() => {
    if (onPledgeUpdateRef) {
      onPledgeUpdateRef.current = () =>
        refetch(hasIntelligentPath ? "emissionReduction" : "emissionReductionOld");
    }
  }, []);

  return (
    <CambioCard
      className={classnames("PathToNetZeroCard", { featured: hasIntelligentPath })}
      title={"Path to Net Zero for " + organizationName}
      label="metric tons"
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        !hasIntelligentPath &&
        canChangeTimeFrame && (
          <Select options={Timeframes} onSelect={setTimeframe} size="small" value={timeframe} />
        )
      }
    >
      {hasInitiallyLoaded ?
        hasIntelligentPath ?
          <LineChart
            data={transformData(emissionReductionModel?.toJSON(), featureConfigurations)}
            xAxisKey="year"
            yAxisChartDataFields={[
              {
                key: "expected_path",
                color: ChartColors.TEAL_DEEP,
                name: "Target Pathway",
                iconShape: "circle-outline-dashed",
                lineType: "dashed",
              },
              {
                key: "grid_decarb_path",
                color: ChartColors.TEAL_DARK,
                name: "Grid Decarbonization",
              },
              {
                key: "retrofit_complete_path",
                color: ChartColors.TEAL_CAMBIO,
                name: "Completed Interventions",
              },
              {
                key: "retrofit_in_progress_path",
                color: ChartColors.LIME,
                name: "Completed & In Progress Interventions",
              },
              {
                key: "retrofit_budget_path",
                color: ChartColors.LEMON,
                name: "Completed, In Progress & Budgeted Interventions",
              },
            ]}
            tooltipLabelKey="year"
            hasLegend
            lineWidth="small"
            referenceDots={[
              {
                x: emissionReductionModel?.toJSON().baseline_year,
                y: emissionReductionModel?.toJSON().baseline_emission,
                color: ChartColors.TEAL_DEEP,
                shape: "dot",
                name: "Baseline Performance",
              },
            ]}
            tooltipValueFormatter={(value) =>
              value.toLocaleString("en-US", { maximumFractionDigits: 1 })
            }
          ></LineChart>
        : <ComposedChart
            data={
              timeframe === PathToNetZeroTimeframe.TOTAL ?
                emissionReductionOldModel?.get("total_path")
              : emissionReductionOldModel?.get("current_year_path").map((entry, i) => ({
                  ...entry,
                  ...emissionReductionOldModel.get("current_year_mitigation_path")[i],
                }))
            }
            height="100%"
            tooltipLabelKey={timeframe === PathToNetZeroTimeframe.TOTAL ? "year" : "month"}
            xAxisKey={timeframe === PathToNetZeroTimeframe.TOTAL ? "year" : "month"}
            yAxisChartDataFields={[
              ...(timeframe === PathToNetZeroTimeframe.TOTAL ?
                ([] as any[])
              : [
                  {
                    key: "accumulated_mitigation",
                    name: "Cumulative Emissions to Mitigate",
                    color: "#FFD72F",
                    type: "bar",
                  },
                ]),
              {
                key: "carbon_emission",
                name: "Path to Net Zero",
                color: "rgb(13, 148, 136)",
                type: "line",
              },
            ]}
            tooltipValueFormatter={(value) =>
              value.toLocaleString("en-US", { maximumFractionDigits: 0 })
            }
          />

      : null}
    </CambioCard>
  );
});
