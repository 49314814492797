import type { ExecutorFunction } from "resourcerer";

import dayjs from "dayjs";
import { useRouter } from "next/router";
import { memo, useLayoutEffect, useState } from "react";
import { useResources, Utils } from "resourcerer";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import WindowChart from "@/components/Charts/WindowChart";
import EmptyState from "@/components/EmptyState";
import Select from "@/components/Select";

import { ChartColors } from "@/js/constants/cambio";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";

import { useDashboardContext } from "../utils";

const getResources: ExecutorFunction<
  "meters" | "meterIntervalData",
  { spaceToken: string; dateRange: [string, string]; meterId?: number }
> = ({ spaceToken, dateRange, meterId }) => ({
  meters: { params: { meter_data_type: "INTERVAL_DATA" }, path: { spaceToken } },
  meterIntervalData: {
    params: { start_date: dateRange[0], end_date: dateRange[1] },
    dependsOn: !!meterId,
    path: { propertyToken: spaceToken, meterId },
  },
});

/**
 * Given a monthly range between the global dashboard date range, and given a meter with interval
 * data, displays interval data in a WindowChart.
 */
export default memo(function IntervalDataCard() {
  const { property, dateRange } = useDashboardContext();
  // enumerated months for the month dropdown because they are limited to what is selected from
  // the global date range picker
  const monthsInDateRange = getMonthsFromDateRange(dateRange);

  const [activeMonth, setActiveMonth] = useState(monthsInDateRange.at(-1));
  const [activeMeter, setActiveMeter] = useState<number>(null);

  const { space_token } = useRouter().query;
  const {
    isLoading,
    hasLoaded,
    hasErrored,
    hasInitiallyLoaded,
    metersCollection,
    metersLoadingState,
    meterIntervalDataModel,
  } = useResources(getResources, {
    spaceToken: space_token as string,
    dateRange: [
      dayjs(activeMonth).startOf("month").format("YYYY-MM-DD"),
      dayjs(activeMonth).endOf("month").format("YYYY-MM-DD"),
    ],
    meterId: activeMeter,
  });

  // when the parent date range on the dashboard changes, we need to update the active month,
  // since it is a subset
  useLayoutEffect(() => {
    setActiveMonth(getMonthsFromDateRange(dateRange).at(-1));
  }, [...dateRange]);

  useLayoutEffect(() => {
    if (Utils.hasLoaded(metersLoadingState) && metersCollection.length) {
      setActiveMeter(metersCollection.at(0).get("meter_id"));
    }
  }, [metersLoadingState, metersCollection]);

  return (
    <CambioCard
      className="IntervalDataCard"
      title="Interval Data"
      label={meterIntervalDataModel.get("unit") || "\xa0"}
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <>
          {Utils.hasLoaded(metersLoadingState) && metersCollection.length ?
            <Select
              disabled={metersCollection.length === 1}
              options={metersCollection.toJSON().map(({ meter_id, utility_meter_id }) => ({
                display: `Meter ${utility_meter_id}`,
                value: meter_id,
              }))}
              onSelect={setActiveMeter}
              size="small"
              value={activeMeter}
            />
          : null}
          <Select
            icon="calendar"
            options={monthsInDateRange.map((date) => ({
              display: dayjs(date).format("MMM YYYY"),
              value: date,
            }))}
            onSelect={setActiveMonth}
            size="small"
            value={activeMonth}
          />
          <CardDownloadButton
            disabled={!hasLoaded || !metersCollection.length}
            name="Interval Data"
            onClick={() => {
              convertToCsvAndDownload(
                [
                  {
                    title: "Start Time",
                    key: "start_date_time",
                    processValue: (val) => dayjs(val).format("MMM DD, YYYY h:mma"),
                  },
                  { title: "Value", key: "data" },
                  { title: "Unit", value: meterIntervalDataModel.get("unit") || "\xa0" },
                ],
                meterIntervalDataModel.get("interval_data"),
                `${property.name} Meter ${metersCollection
                  .get(activeMeter)
                  ?.get("utility_meter_id")} ${dayjs(activeMonth).format(
                  "MMM YYYY",
                )} Interval Data`,
              );
            }}
          />
        </>
      }
    >
      {hasInitiallyLoaded ?
        !meterIntervalDataModel.get("interval_data").length ?
          <EmptyState message="No data for this time period." />
        : <WindowChart
            data={meterIntervalDataModel.get("interval_data")}
            // a week's worth of data points
            windowLength={(60 / meterIntervalDataModel.get("interval")) * 24 * 7}
            interval={meterIntervalDataModel.get("interval")}
            yAxisChartDataFields={[
              { key: "data", color: ChartColors.TEAL_CAMBIO, name: "Value", isAnimated: false },
            ]}
            xAxisKey="start_date_time"
          />

      : Utils.hasLoaded(metersLoadingState) && !metersCollection.length ?
        <EmptyState message="No interval data meters on this property" />
      : null}
    </CambioCard>
  );
});

/**
 * Governs which months to show in the month picker in this component, which is the set of full
 * months that lie within the current dashboard date range.
 */
export const getMonthsFromDateRange = (dateRange: [string, string]) => {
  // only want full months
  const endDate = dayjs(dateRange[1]).startOf("month");
  const startDate =
    // check if the first entry is the first day of the month or both dates
    // are in the same month
    (
      dayjs(dateRange[0]).startOf("month").isSame(dateRange[0], "day") ||
      dayjs(dateRange[0]).isSame(dateRange[1], "month")
    ) ?
      dayjs(dateRange[0]).startOf("month")
    : dayjs(dateRange[0]).startOf("month").add(1, "month");

  // minimum one month
  const numberOfMonths = Math.max(endDate.diff(startDate, "month"), 1);

  return Array.from({ length: numberOfMonths }, (_, i) =>
    startDate.add(i, "month").format("YYYY-MM-DD"),
  );
};
