import type { BenchmarkingData } from "@/Api/generated/models/BenchmarkingData";
import type { BenchmarksDrillDownInfoOption } from "@/pages/DrilldownPage/BenchmarksDrillDownDrawer";

import { useRouter } from "next/router";
import { LoadingStates, Utils } from "resourcerer";

import ChartLabel from "@/components/Charts/ChartLabel";
import Delta from "@/components/Delta";
import MetricValue from "@/components/MetricValue";
import SvgIcon from "@/components/SvgIcon";

import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { getEmptyCell, getLoadingCell } from "@/js/utils/partials";

import { BenchmarksDrillDownDrawer } from "@/pages/DrilldownPage/BenchmarksDrillDownDrawer";

import { DashboardContext } from "..";
import { useDashboardContext } from "../utils";

/**
 * Highlights a specific overview value for a metric along with its percent change in the
 * previous period.
 */
export default function ValueSpotlight({
  value,
  units,
  label,
  loadingState,
  drillDownOption,
  benchmarkName,
  benchmarks = [],
  dateRange,
}: {
  /** String because these numbers are pre-formatted */
  value: string;
  units: string;
  label: string;
  /** Allows us to show loading and error states for each value */
  loadingState: LoadingStates;
  /** When present, we can click in and view the sidebar for more info on the metric */
  drillDownOption?: BenchmarksDrillDownInfoOption;
  /** The key to dig out percent change from the benchmarks */
  benchmarkName?: string;
  /** Benchmarks from energy source/consumption data */
  benchmarks?: BenchmarkingData[];
  dateRange?: [string, string];
  onChangeDateRange?: (range: [string, string]) => void;
}) {
  const { space_token } = useRouter().query;
  const { openPanel } = useSidePanelContext();
  const hasLoaded = Utils.hasLoaded(loadingState);
  const hasErrored = Utils.hasErrored(loadingState);
  const isClickable = hasLoaded && drillDownOption !== undefined;

  const dashboardContextValue = useDashboardContext();

  const changePercentage =
    benchmarkName ?
      benchmarks.find((benchmark) => benchmark.metric_name === benchmarkName)?.spot_trend
        ?.change_percentage
    : null;

  return (
    <button
      className="ValueSpotlight"
      disabled={!isClickable}
      onClick={
        isClickable ?
          () => {
            openPanel(
              // this won't automatically get passed along to the drawer, so we need to wrap it
              <DashboardContext.Provider value={dashboardContextValue}>
                <BenchmarksDrillDownDrawer
                  initialDateRange={dateRange}
                  isAggregate={!space_token}
                  selectedBenchmarkDrilldownOption={drillDownOption}
                />
              </DashboardContext.Provider>,
            );
          }
        : null
      }
    >
      <h5>
        <span className="label">{label}</span>
        {drillDownOption !== undefined ?
          <SvgIcon name="side-panel" />
        : null}
      </h5>
      <div>
        <MetricValue size="large">
          {hasErrored ?
            getEmptyCell()
          : !hasLoaded ?
            getLoadingCell()
          : value ?? "N/A"}
        </MetricValue>
        {hasLoaded && (
          <div>
            <ChartLabel>{units}</ChartLabel>
            {benchmarkName && typeof changePercentage === "number" && (
              <Delta value={changePercentage} inverted />
            )}
          </div>
        )}
      </div>
    </button>
  );
}
