export function transformData(intelligentPtnzData: any, featureConfigurations: any): any[] {
  const paths = [
    "retrofit_complete_path",
    "retrofit_in_progress_path",
    "retrofit_budget_path",
    "expected_path",
    "grid_decarb_path",
  ];

  // Extract all unique years from the lists
  let allYears = new Set<number>(); // Make sure Set only contains numbers

  paths.forEach((path) => {
    intelligentPtnzData[path]?.forEach((dataPoint: any) => {
      allYears.add(Number(dataPoint.year)); // Convert every year to a number
    });
  });

  //@ts-ignore
  allYears = [...allYears].sort((a, b) => a - b); // sort years

  // Construct the desired array
  //@ts-ignore
  const transformedData: any[] = allYears.map((year) => {
    const yearData: any = { year };

    paths.forEach((path) => {
      const match = intelligentPtnzData[path].find((dp: any) => Number(dp.year) === year);

      yearData[path] = match ? match.value : null;
    });

    return yearData;
  });

  return transformedData;
}
