import { memo } from "react";

import { CardDownloadButton } from "@/components/Button/CardDownloadButton";
import CambioCard from "@/components/CambioCard";
import BarChart from "@/components/Charts/BarChart";
import LineChart from "@/components/Charts/LineChart";

import { ChartColors } from "@/js/constants/cambio";
import { convertToCsvAndDownload } from "@/js/utils/csvCreation";
import { processUnitValue, unitFormatter } from "@/js/utils/unitFormatter";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getSelectedYear, useDashboardContext, useDashboardResources } from "../utils";

export default memo(function ElectricityCard() {
  const { organizationName, featureConfigurations } = useAppContext();
  const { property, dateRange } = useDashboardContext();
  const { energyConsumptionModel, isLoading, hasErrored, hasInitiallyLoaded } =
    useDashboardResources(["property", "energyConsumption"], {
      useAnnualizedData: !!featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED,
    });
  const electricityData =
    featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
      energyConsumptionModel.get("annual_electricity_consumption") || []
    : energyConsumptionModel.get("monthly_electricity_consumption");
  const unit = unitFormatter(energyConsumptionModel.get("electricity_unit")) || "kWh";

  return (
    <CambioCard
      title="Electricity"
      label={hasInitiallyLoaded ? unit : "\xa0"}
      hasErrored={hasErrored}
      isLoading={isLoading}
      actionBar={
        <CardDownloadButton
          name="Electricity"
          onClick={() => {
            convertToCsvAndDownload(
              [
                { title: "End Date", key: "date" },
                {
                  title: "Electricity",
                  key: "value",
                  processValue: (value) => {
                    return parseFloat(String(value)).toFixed(0);
                  },
                },
                { title: "Unit of Measurement", value: unit, processValue: processUnitValue },
              ],
              electricityData,
              (property ? property.name : organizationName) + " Electricity",
            );
          }}
        />
      }
    >
      {hasInitiallyLoaded ?
        !featureConfigurations.ORG_LEVEL_ANNUALIZED_DATA_ENABLED ?
          <LineChart
            data={electricityData}
            hasReferenceLine
            yAxisChartDataFields={[{ key: "value", color: ChartColors.TEAL_CAMBIO, name: "Usage" }]}
          />
        : <BarChart
            data={electricityData}
            hasLegend={false}
            hasReferenceLine
            yAxisChartDataFields={[
              {
                key: "value",
                color: ChartColors.TEAL_CAMBIO,
                style: (entry) =>
                  entry.date !== getSelectedYear(dateRange) ? { opacity: 0.3 } : {},
                name: "Usage",
              },
            ]}
            xAxisKey="date"
          />

      : null}
    </CambioCard>
  );
});
