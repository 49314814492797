import type { FilterTypes } from "@/components/Filters";
import type { ExecutorFunction } from "resourcerer";

import { useRouter } from "next/router";
import { memo } from "react";
import { useResources } from "resourcerer";

import Button from "@/components/Button";
import CambioCard from "@/components/CambioCard";
import { PieChart } from "@/components/Charts/PieChart";

import useModalContext from "@/js/hooks/useModalContext";

import { useAppContext } from "@/layouts/AppLayout/AppContext";

import { getFiltersQueryParams, getSpaceQueryParam, isCampus, useDashboardContext } from "../utils";
import AggregatedPropertyDataCompletenessModal from "./AggregatedPropertyDataCompletenessModal";

const getResources: ExecutorFunction<
  "onboardingSummary",
  {
    organizationId: string;
    spaceId: number;
    filters: Record<FilterTypes, string>;
    space_token: string;
  }
> = (props) => ({
  onboardingSummary: {
    params: {
      organization_id: props.organizationId,
      ...getSpaceQueryParam(props.space_token, props.spaceId, "space_id"),
      ...props.filters,
    },
    ...(props.space_token && !isCampus(props.space_token) ? { dependsOn: !!props.spaceId } : {}),
  },
});

export default memo(function DataCompletenessCard() {
  const { propertyLoadingStates, filters, spaceId } = useDashboardContext();
  const router = useRouter();
  const { space_token } = router.query as { space_token: string };
  const { launch } = useModalContext();
  const isAggregated = !spaceId;
  const { featureConfigurations, organizationId } = useAppContext();

  const { hasInitiallyLoaded, isLoading, hasErrored, onboardingSummaryModel } = useResources(
    getResources,
    {
      organizationId,
      space_token,
      spaceId,
      filters: getFiltersQueryParams(filters),
    },
  );
  const { summary, property_details } = onboardingSummaryModel.toJSON();

  const totalArea = property_details?.reduce(
    (memo, { units }) => memo + units.reduce((imemo, { area }) => imemo + area, 0),
    0,
  );
  const areaOnboarded = property_details?.reduce(
    (memo, { units }) =>
      memo +
      units.reduce((imemo, { area, status }) => (status === "COMPLETE" ? imemo + area : imemo), 0),
    0,
  );
  const percentageComplete = (areaOnboarded / totalArea) * 100;

  return (
    <CambioCard
      title={featureConfigurations.INTELLIGENT_ONBOARDED ? "Data Coverage" : "Onboarding Progress"}
      label={
        featureConfigurations.INTELLIGENT_ONBOARDED ?
          "% of data coverage during [year]"
        : "% of properties onboarded"
      }
      actionBar={
        <Button
          flavor="secondary"
          disabled={!hasInitiallyLoaded}
          onClick={() => {
            if (isAggregated) {
              launch(
                <AggregatedPropertyDataCompletenessModal
                  aggregatePropertyDetails={onboardingSummaryModel.get("property_details")}
                  summary={summary}
                />,
              );
            } else {
              router.push(`${router.asPath.split("?")[0].split("/").slice(0, -1).join("/")}/units`);
            }
          }}
          size="small"
        >
          {isAggregated ? "View" : "View Units"}
        </Button>
      }
      hasErrored={hasErrored || propertyLoadingStates.hasErrored}
      isLoading={isLoading || propertyLoadingStates.isLoading}
    >
      {hasInitiallyLoaded ?
        <PieChart
          data={[
            { name: "Complete", value: percentageComplete },
            { name: "Remaining", value: 100 - percentageComplete },
          ]}
          centeredMetric={percentageComplete.toFixed(0) + "%"}
          centeredMetricSubtitle={featureConfigurations.INTELLIGENT_ONBOARDED ? "coverage" : ""}
          dataKey="value"
          colorFlavor="teal-gray"
          tooltipLabelKey="name"
          nameFormatter={(name) => name}
          tooltipValueFormatter={(value) => value.toFixed(0) + "%"}
        />
      : null}
    </CambioCard>
  );
});
