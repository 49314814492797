import type { ClientEnhancedSpace } from "@/js/models/PropertiesCollection";
import type { ExecutorFunction } from "resourcerer";

import { useRouter } from "next/router";
import { useResources } from "resourcerer";

import IconButton from "@/components/Button/IconButton";
import CambioCard from "@/components/CambioCard";
import RadarChart from "@/components/Charts/RadarChart";
import { IconsType } from "@/components/SvgIcon";

import useSidePanelContext from "@/js/hooks/useSidePanelContext";
import { formatPercentile } from "@/js/utils/stringFormatter";

import { ClimateResilienceDrawer } from "@/pages/DrilldownPage/ClimateResilienceDrawer";

const getResources: ExecutorFunction<
  "climateRisks",
  { organization_token: string; space_token: string }
> = ({ organization_token, space_token }) => ({
  climateRisks: {
    path: {
      orgToken: organization_token,
      propertyToken: space_token,
    },
  },
});

const climateCategories = [
  { key: "HURRICANE", name: "Hurricane", icon: "hurricane" },
  { key: "WILDFIRE", name: "Wildfire", icon: "wildfire" },
  { key: "EARTHQUAKE", name: "Earthquake", icon: "earthquake" },
  { key: "COASTAL_FLOODING", name: "Flood", icon: "flood" },
  { key: "HEAT_WAVE", name: "Heatwave", icon: "heatwave" },
];

export default function PhysicalClimateRiskCard({ property }: { property: ClientEnhancedSpace }) {
  const { openPanel } = useSidePanelContext();
  const { climateRisksCollection, isLoading } = useResources(getResources, {
    ...(useRouter().query as { organization_token: string; space_token: string }),
  });

  const data = [
    {
      name: "Risk Index",
      ...climateCategories.reduce(
        (acc, category) => {
          const climateRisk = climateRisksCollection
            .toJSON()
            .find((climateRisk) => climateRisk?.category === category.key);

          acc[category.key] = climateRisk?.risk_score ?? 0;

          return acc;
        },
        {} as Record<string, number>,
      ),
    },
    {
      name: "Expected Annual Loss",
      ...climateCategories.reduce(
        (acc, category) => {
          const climateRisk = climateRisksCollection
            .toJSON()
            .find((climateRisk) => climateRisk?.category === category.key);

          acc[category.key] = climateRisk?.eal_score ?? 0;

          return acc;
        },
        {} as Record<string, number>,
      ),
    },
  ];

  return (
    <CambioCard
      title="Physical Climate Risk"
      isLoading={isLoading}
      actionBar={
        <IconButton
          disabled={property?.address__country !== "USA"}
          onClick={() => openPanel(<ClimateResilienceDrawer />)}
          size="small"
          icon="side-panel"
        />
      }
      className="PhysicalClimateRiskCard"
    >
      {property && property?.address__country === "USA" && (
        <RadarChart
          dataFields={climateCategories as { icon: IconsType; name: string; key: string }[]}
          data={data}
          tooltipValueFormatter={(value) =>
            formatPercentile(parseFloat(value?.toFixed(0))) + " percentile"
          }
        />
      )}
      {property && property?.address__country !== "USA" && (
        <div className="non-us-asset">Physical risk data not yet available for non-US assets</div>
      )}
    </CambioCard>
  );
}
